<template>
    
</template>

<script>
export default {
    props: ["show"],
    data() {
        return {
            // show: this.show,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>