<template>
    <div>
          <video
            ref="playerChild"
            class="video-player"
            playsInline
            crossOrigin
            controls
            crossorign='*'
            style="--plyr-color-main: #231F20;"
            :data-poster="videoUrl + '?spm=a2c4g.11186623.2.1.yjOb8V&x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast'"
          >
            <source :src="videoUrl"></source>
          </video>
    </div>
</template>

<script>
import Plyr from "plyr";
export default {
    props: {
        videoUrl: {
            type: String,
            default: "",
        },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const player = new Plyr(this.$refs.playerChild, {
                debug: false,
                settings: ["quality"],
                speed: { selected: 1, options: [1, 1.5, 2, 3, 4, 5] },
            });
            window.player = player;
        },
        async getVedioHotList() {
            const params = {
                pageNo: 1,
                pageSize: 1000,
            };
            const { data } = await getVedioHotList(params);
            this.videoList = data.list;
        },
    },
};
</script>

<style lang="scss" scoped>
.video-player {
    width: 100%;
    height: 406px;
}
</style>