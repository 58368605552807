<template>
    <div class="swiper-container" ref="pointSwiper">
        <div class="swiper-wrapper">
            <div
                class="swiper-slide"
                :style="{ height: `${height}px` }"
                v-for="(item, index) in swiperList"
                :key="index"
            >
                <!-- <video-plyr
                    v-if="item.typs == 'vedio'"
                    :videoUrl="item.url"
                ></video-plyr> -->
                <van-image width="100%" height="100%" :src="item.url" />
            </div>
        </div>
    </div>
</template>

<script>
import videoPlyr from "./plyr.vue";
export default {
    components: {
        videoPlyr,
    },
    name: "home-swiper",
    props: ["height", "slidesPerView", "spaceBetween", "swiperList"],
    data() {
        return {};
    },
    mounted() {
        setTimeout(() => {
            new Swiper(this.$refs.pointSwiper, {
                slidesPerView: 1.1,
                spaceBetween: 0,
                centeredSlides: false,
                loop: true,
                touchRatio: 2,
            });
        }, 1200);
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.swiper-container {
    width: 100%;
    height: auto;
}
.swiper-slide {
    width: 100%;
    // height: 240px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.9);
    border-radius: 15px;
    overflow: hidden;
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
    transform: scale(0.9);
}
</style>