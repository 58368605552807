<template>
  <div class="point-details">
    <div class="point-banner">
      <!-- <point-swiper
                :height="200"
                :swiperList="pointLogoList"
                :slidesPerView="1.3"
                :spaceBetween="10"
            ></point-swiper> -->
      <div class="swiper-container" ref="pointSwiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in pointLogoList"
            :key="index"
          >
            <video-plyr
              v-if="item.typs == 'vedio'"
              :videoUrl="item.url"
            ></video-plyr>
            <van-image
              v-else
              width="100%"
              :src="item.url"
              class="swiper-iamge"
              fit="cover"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="point-content">
      <div class="u-p-l-20 u-p-r-20">
        <div>
          <van-row class="point-list u-m-b-30">
            <van-col span="18" style="color: #666">
              <div class="point-title">
                {{ pointDetail.lpAddress }}
              </div>
              <div class="u-m-t-30 point-subTitle">
                开放信息：{{ pointDetail.information }}
              </div>
            </van-col>
            <van-col span="6">
              <div class="point-icon" @click="handlePunch">
                <van-icon name="location" size="40" color="#B72E32" />
                <div>去打卡</div>
              </div>
            </van-col>
          </van-row>
          <van-divider hairline style="margin: 0" />
        </div>
        <div>
          <van-row class="point-list" @click="openPage">
            <van-col span="18" style="color: #666">{{
              pointDetail.lpAddress
            }}</van-col>
            <van-col span="6" style="text-align: right"
              ><van-icon name="arrow" />
            </van-col>
          </van-row>
        </div>
      </div>

      <activity-card>
        <van-tabs v-model="active" scrollspy class="details-tabs">
          <van-tab title="听一听">
            <div class="recommend-title u-m-b-20 u-m-t-30">音频</div>
            <div class="details-content active">
              <div class="audio-container">
                <van-image
                  width="100"
                  height="80"
                  :src="pointDetail.lpHearImg"
                  :radius="10"
                  fit="cover"
                ></van-image>
                <div class="audio-content">
                  <div class="audio-title">
                    {{ pointDetail.lpHearTitle }}
                  </div>
                  <audio-player
                    ref="audioPlayer"
                    :audio-list="audioList.map((elm) => elm.url)"
                    :before-play="handleBeforePlay"
                    theme-color="#ff2929"
                    :show-prev-button="false"
                    :show-next-button="false"
                    :show-volume-button="false"
                    :show-play-button="false"
                    :show-playback-rate="false"
                  />
                </div>
                <div class="sound-button" @click="handleAudioPlay">
                  <van-icon
                    :name="this.isPlaying ? 'pause-circle' : 'play-circle'"
                    color="#B72E32"
                    size="30"
                  ></van-icon>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="简介">
            <div class="recommend-title u-m-t-30" v-if="pointDetail.lpHearText">
              景点简介
            </div>
            <div class="details-content" v-html="pointDetail.lpHearText"></div>
          </van-tab>
          <van-tab title="评论">
            <div
              class="recommend-title u-m-b-20 u-m-t-30"
              v-if="mllLinePoints.length > 0"
            >
              用户评论
              <span class="recommend-subTitlte u-m-l-20"
                >已有{{ pointDetail.lpTotal }}人完成打卡</span
              >
            </div>
            <div
              class="comment-container"
              v-for="(item, index) in mllLinePoints"
              :key="index"
            >
              <div class="comment-logo">
                <van-image
                  width="50px"
                  height="50px"
                  radius="50px"
                  :src="item.avator"
                  fit="cover"
                />
                <div class="comment-name">
                  {{ item.uname }}
                </div>
              </div>
              <div class="comment-content">
                <div class="">{{ item.commentText }}</div>
                <van-image
                  width="100%"
                  height="120px"
                  radius="0"
                  :src="item.imgUrl"
                  class="u-m-t-30"
                  fit="contain"
                />
                <div class="comment-time u-m-t-20">
                  {{ item.createdAt }}
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </activity-card>
    </div>

    <van-popup v-model="show" round class="popup-container" closeable>
      <div>
        <van-icon
          name="invitation"
          size="120"
          :color="punchStatus ? '#B72E32' : '#999'"
        />
        <div class="popup-title u-m-t-20">
          {{ punchStatus ? "恭喜你，打卡成功～" : "很抱歉，打卡失败～" }}
        </div>
        <div class="popup-title u-m-t-20" v-if="errMessage">
          {{ errMessage }}
        </div>
      </div>
    </van-popup>
    <div class="footer-point" @click="openComment">+ 发表评论</div>
    <!-- <router-link :to="{ path: '/point/comment', query: { id: commonId } }">
            <div class="footer-point">+ 发表评论</div>
        </router-link> -->
    <float-ball :icon="'records'" :src="`/answer/?id=${commonId}`"></float-ball>
    <!-- <follow-model :show="followShow"></follow-model> -->
    <van-popup v-model="followShow" round class="popup-container" closeable>
      <div>
        <van-image
          width="120"
          height="120"
          src="http://oss.hkrin.com/userInfo/10/20211114094734qrcode_rw.jpg"
        />
        <div class="popup-title u-m-t-20">请先扫码关注微信公众号</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getPointDetail, setLinePunch, getWeixinJssdk } from "@/service";
import pointSwiper from "./components/swiper.vue";
import audioSound from "./components/audio.vue";
import floatBall from "@/components/common/floatBall.vue";
import activityCard from "../activity/components/activity-card.vue";
import AudioPlayer from "@liripeng/vue-audio-player";
import videoPlyr from "./components/plyr.vue";
import followModel from "@/components/follow/follow.vue";
// import "@liripeng/vue-audio-player/lib/vue-audio-player.css";
export default {
  components: {
    pointSwiper,
    activityCard,
    floatBall,
    audioSound,
    AudioPlayer,
    videoPlyr,
    followModel,
  },
  data() {
    return {
      show: false,
      punchStatus: true,
      active: 0,
      commonId: "",
      detailHtml: "123123213",
      swiperList: ["Slide 1", "Slide 2", "Slide 3"],
      pointDetail: {},
      mllLinePoints: [],
      pointLogoList: [],
      isPlaying: false,
      positions: [],
      errMessage: "",
      audioList: [
        {
          url: "",
        },
      ],
      followShow: false,
    };
  },
  watch: {
    pointLogoList: function (value) {
      if (value.length > 0) {
        setTimeout(() => {
          new Swiper(this.$refs.pointSwiper, {
            slidesPerView: 1.1,
            spaceBetween: 0,
            centeredSlides: false,
            loop: true,
            touchRatio: 2,
          });
        }, 200);
      }
    },
  },
  mounted() {
    const { id = "" } = this.$route.query;
    this.commonId = id;
    this.getPointDetail();
    var u = navigator.userAgent;
    if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
      this.initWechat();
    }
  },
  methods: {
    async initWechat() {
      const { nonceStr, jsapi_ticket, signature, timestamp } =
        await getWeixinJssdk({ url: window.location.href });
      if (nonceStr) {
        wx.config({
          debug: false,
          appId: "wx5a5c6ad0371692ec",
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: signature,
          jsApiList: ["checkJsApi", "openLocation", "getLocation"],
        });
      }
    },
    async getPointDetail() {
      const { id = "" } = this.$route.query;
      this.commonId = id;
      const params = {
        pageNo: 1,
        pageSize: 1000,
        commonId: id,
      };
      const { data } = await getPointDetail(params);
      this.pointDetail = data;
      this.mllLinePoints = data.mllLinePoints || [];
      this.pointLogoList = JSON.parse(data.lpUrl) || [];
      this.positions = [data.lpLongitude, data.lpLatitude];
      this.audioList = data.lpHearUrl ? [{ url: data.lpHearUrl }] : [];
      document.title = data.lpAddress;
    },
    async handlePunch() {
      if (!this.$isWeixin) {
        this.followShow = true;
        return false;
      }

      try {
        const { latitude = "", longitude = "" } =
          await this.$wxsdk.getLocation();
        const { id } = this.pointDetail;
        const params = {
          linePonitId: id,
          latitude: latitude,
          longitude: longitude,
        };
        const { data, code, message } = await setLinePunch(params);
        if (code == 0) {
          this.punchStatus = true;
          this.$nextTick(() => {
            this.show = true;
          });
          return false;
        }
        console.log(message, "message");
        this.punchStatus = false;
        this.errMessage = message;
        this.$nextTick(() => {
          this.show = true;
        });
      } catch (err) {
        this.errMessage = err.message;
        this.punchStatus = false;
        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
    // 播放前做的事
    handleBeforePlay(next) {
      next(); // 开始播放
    },
    handleAudioPlay() {
      if (this.$refs.audioPlayer.isPlaying) {
        this.$refs.audioPlayer.pause();
        this.isPlaying = false;
        return false;
      }
      this.$refs.audioPlayer.play();
      this.isPlaying = true;
    },
    openComment() {
      if (!this.$isWeixin) {
        this.followShow = true;
        return false;
      }

      this.$router.push(`/point/comment?id=${this.commonId}`);
    },
    openPage() {
      const { lpAddress, lpLongitude, lpLatitude } = this.pointDetail;
      this.$wxsdk.openLocation({
        latitude: lpLatitude,
        longitude: lpLongitude,
        name: lpAddress,
        address: lpAddress,
      });
      // sessionStorage.setItem("positions", JSON.stringify(this.positions));
      // sessionStorage.setItem(
      //     "address",
      //     JSON.stringify([this.pointDetail.lpAddress])
      // );
      // this.$nextTick(() => {
      //     this.$router.push("/maps");
      // });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/mixin";
@import "@/style/_varibles";

.point-details {
  .details-tabs {
    padding-bottom: 30px;
  }
  .point-banner {
    padding: 20px 0 0 20px;
    .swiper-container {
      width: 100%;
      height: auto;
    }
    .swiper-slide {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      text-align: center;
      transition: 300ms;
      transform: scale(1);
      overflow: hidden;
    }
    .swiper-slide-active,
    .swiper-slide-duplicate-active {
      transform: scale(1);
    }
  }
  .swiper-iamge {
    height: 430px;
    border-radius: 20px;
    padding: 20px;
    img {
      border-radius: 20px;
    }
  }
  .point-content {
    padding: 32px 38px 140px 38px;
    background: #fff;
    .point-title {
      @include sc(32px, $--color-text-regular);
      font-weight: 500;
    }
    .point-subTitle {
      @include sc(24px, $--color-text-regular);
      line-height: 40px;
    }
    .point-list {
      min-height: 114px;
      @include sc(24px, $--color-text-regular);
      display: flex;
      align-items: center;
      .list-appointment {
        color: $--color-text-primary;
      }
      .point-icon {
        text-align: center;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 1px;
          height: 100%;
          background: rgb(223, 223, 223);
        }
      }
    }
    .details-content {
      @include sc(24px, $--color-text-secondary);
      padding: 30px;
      overflow: hidden;
      img {
        max-width: 100%;
      }
      &.active {
        padding: 20px 20px 30px 20px;
      }
    }
    .recommend-title {
      @include sc(28px, $--color-text-regular);
      padding-left: 40px;
      .recommend-subTitlte {
        @include sc(22px, $--color-text-secondary);
      }
    }
    .comment-container {
      padding: 30px 20px 50px;
      display: flex;
      align-items: flex-start;
      .comment-name {
        @include sc(28px, $--color-text-regular);
        text-align: center;
      }
      .comment-content {
        @include sc(28px, $--color-text-regular);
        padding-left: 20px;
        flex: 1;
        .comment-time {
          @include sc(22px, $--color-text-secondary);
        }
      }
    }
  }
  .audio-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    color: #231f20;
    background: #f1f1f1;
    padding: 20px 20px;
    border-radius: 20px;
    .audio-content {
      margin-left: 30px;
      margin-right: 30px;
      width: 100%;
    }
  }
  .footer-point {
    @include fj(center);
    @include sc(28px, #fff);
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background: $--color-text-primary;
  }
  .popup-container {
    @include fj(center);
    align-items: center;
    width: 676px;
    height: 460px;
    background: #ffffff;
    border-radius: 40px;
    text-align: center;
    .popup-title {
      @include sc(22px, $--color-text-regular);
    }
  }
  .fixed-icon {
    position: fixed;
  }

  .van-tabs__line {
    background-color: #b72e32;
  }
}
</style>