<template>
    <div>
        <audio-player
            ref="audioPlayer"
            :audio-list="audioList.map((elm) => elm.url)"
            :before-play="handleBeforePlay"
            theme-color="#ff2929"
            :show-prev-button="false"
            :show-next-button="false"
            :show-volume-button="false"
            :show-play-button="false"
            :show-playback-rate="false"
        />
    </div>
</template>


<script>
import AudioPlayer from "@liripeng/vue-audio-player";
// import "@liripeng/vue-audio-player/lib/vue-audio-player.css";

export default {
    components: {
        AudioPlayer,
    },
    data() {
        return {
            currentAudioName: "",
            audioList: [
                {
                    name: "",
                    url: "",
                },
            ],
        };
    },
    methods: {
        // 播放前做的事
        handleBeforePlay(next) {
            // 这里可以做一些事情...
            this.currentAudioName =
                this.audioList[this.$refs.audioPlayer.currentPlayIndex].name;

            next(); // 开始播放
        },
    },
};
</script>

<style lang="scss">
.audio__progress-point {
    display: none;
    opacity: 0;
}
.audio-player {
    .audio__btn-wrap {
        display: none;
    }
}
</style>